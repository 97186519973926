<template>
  <div>
    <searchedContents :contents="types" :localLang="localLang"></searchedContents>
  </div>
</template>

<script>
export default {
  props: ["types", "localLang"],
  data() {
    return {};
  },
  created() {
    console.log("types in search slider", this.types);
  },
  components: {
    searchedContents: () =>
      import(
        /* webpackChunkName: "searchedContents" */ "./SearchedContentsCarousel.vue"
      )
  }
};
</script>

<style lang="scss" scoped>
</style>